import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { createI18n } from "vue-i18n";
import zh from "./language/zh-TW.json";
//TODO 暫時將英文語系拿掉
//import en from "./language/en-US.json";



// vue app
const app = createApp(App);

// bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
// template custom css
import "./assets/scss/common.scss";

import {
  Colors,
  monthList,
  today,
  yesterday,
  currentMonth,
} from "./utilities/globalProperties.js";
//import attr from "@/utilities/attr";

//註冊Icon
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

import {createHead} from "@vueuse/head"
const head = createHead()
app.use(head)

//全域變更meta值處理
// app.config.globalProperties.$setMetaData = function (metaData) {
//   useHead({
//     title: () => metaData.title,
//     meta: [
//       {
//         name: 'description',
//         content: () => metaData.description
//       },
//       {
//         name: 'keywords',
//         content: () => metaData.keywords
//       }
//     ],
//   })
// };


//宣告全局的訊息盒處理
import { ElMessage, ElMessageBox } from "element-plus";
import "element-plus/theme-chalk/index.css";
//宣告全域用的錯誤訊息
app.config.globalProperties.errorMessage = function (message, error = "") {
  let errorMsg = error ? error.response.data.msg : "";
  errorMsg = errorMsg ? errorMsg : message;
  ElMessage({
    message: errorMsg,
    type: "error",
  });
};
app.config.globalProperties.$ELEMENT = {};
app.use(ElMessage);
app.use(ElMessageBox);

app.config.globalProperties.$Colors = Colors;
app.config.globalProperties.$monthList = monthList;
app.config.globalProperties.$today = today;
app.config.globalProperties.$yesterday = yesterday;
app.config.globalProperties.$currentMonth = currentMonth;

const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem("locale") ?? "zh-TW",
  fallbackLocale: "zh-TW",
  messages: {
    "zh-TW": zh,
    //"en-US": en,
  }
});
const { t } = i18n.global;
/**
 * 語系對應函式處理
 * @param obj {可為字串或是物件(有帶langKey)}
 * @param nameKey
 * @returns {*}
 */
app.config.globalProperties.$lang = (obj,nameKey)=>{
  let str = '';
  let _lang = '';
  if(typeof obj === 'string'){  //判斷是否為字串，直接做為語系KEY值
    str = t(obj);
  }else if (obj.langKey) {  //如為物件，則檢查是否有帶 langKey
    _lang = t(obj.langKey);
    str = _lang == obj.langKey ? obj[nameKey] : _lang;
  } else {  //以上條件不成立，直接帶原對應值
    str = obj[nameKey] ? obj[nameKey] : ('ERROR '+nameKey);
  }
  return str;
};
app.use(i18n);

//手機裝置判斷
app.config.globalProperties.$isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

//
// // custom directive alert
// app.directive("nk-alert", {
//   mounted(elem, binding) {
//     let alertTemplate = function (selector, message, variant) {
//       const target = document.getElementById(selector);
//       const wrapper = document.createElement("div");
//       wrapper.innerHTML = `<div class="alert alert-${variant} alert-dismissible" role="alert">
//                     <div>${message}</div>
//                     <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
//             </div>`;
//       target.append(wrapper);
//     };
//
//     elem.addEventListener("click", function () {
//       alertTemplate(
//         binding.value.target,
//         binding.value.content,
//         binding.arg || "primary"
//       );
//     });
//   },
// });
//
// // custom directive for Modal
// import { Modal } from "bootstrap";
//
// // Modal
// app.directive("nk-modal", {
//   mounted(elem, binding) {
//     const modalId = elem.getAttribute("href") || binding.value;
//     const modal = new Modal(modalId);
//
//     elem.addEventListener("click", function (e) {
//       e.preventDefault();
//       modal.show();
//     });
//   },
// });
//
// // custom directive for Offcanvas
// import { Offcanvas } from "bootstrap";
//
// // Offcanvas
// app.directive("nk-offcanvas", {
//   mounted(elem, binding) {
//     const offcanvasId = elem.getAttribute("href") || binding.value;
//     const offcanvas = new Offcanvas(offcanvasId);
//
//     elem.addEventListener("click", function (e) {
//       e.preventDefault();
//       offcanvas.show();
//     });
//   },
// });
//
// // custom directive for Tab
// import { Tab } from "bootstrap";
//
// // Tab
// app.directive("nk-tab", {
//   mounted(elem, binding) {
//     if (binding.value) {
//       let setAttrName = elem.tagName === "a" ? "href" : "data-bs-target";
//       attr(elem, setAttrName, binding.value);
//     }
//     const tab = new Tab(elem);
//     elem.addEventListener("click", function (e) {
//       e.preventDefault();
//       tab.show();
//     });
//   },
// });
//
// // custom directive toast
// import { Toast } from "bootstrap";
//
// app.directive("nk-toast", {
//   mounted(elem, binding) {
//     const toastLive = document.getElementById(binding.value);
//     elem.addEventListener("click", function () {
//       const toast = new Toast(toastLive);
//       toast.show();
//     });
//   },
// });
//
// // custom directive for Tooltip
// import { Popover } from "bootstrap";
//
// // popover
// app.directive("nk-popover", {
//   mounted(elem, binding) {
//     new Popover(elem, {
//       placement: binding.arg || "top",
//       title: binding.value.title !== undefined ? binding.value.title : "",
//       content: binding.value.content !== undefined ? binding.value.content : "",
//       customClass:
//         binding.value.customClass !== undefined
//           ? binding.value.customClass
//           : "",
//       trigger:
//         binding.value.trigger !== undefined ? binding.value.trigger : "click",
//       container:
//         binding.value.container !== undefined
//           ? binding.value.container
//           : "body",
//       offset: binding.value.offset !== undefined ? binding.value.offset : "0,8",
//       html: true,
//     });
//   },
// });
//
// // custom directive for Tooltip
// import { Tooltip } from "bootstrap";
//
// // tooltip
// app.directive("nk-tooltip", {
//   mounted(elem, binding) {
//     new Tooltip(elem, {
//       placement: binding.arg || "top",
//       title: binding.value,
//       html: true,
//     });
//   },
// });

// vue app
app.use(router).mount("#app");
