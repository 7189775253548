/**
 * pages頁需要事先宣告處理位置
 */

// import pages--原套版頁面
export const Home = () => import("@/views/home/Home.vue");
export const Discover = () => import("@/views/discover/Discover.vue");
export const News = () => import("@/views/news/News.vue");
export const Article = () => import("@/views/news/Article.vue");

export const EventsHistory = () => import("@/views/events/EventsHistory.vue");
export const EventsArticle = () => import("@/views/events/Article.vue");
export const Privacy = () => import("@/views/information/Information.vue"); //隱私權政策
export const Results = () => import("@/views/results/Results.vue"); //搜尋結果
export const Contact = () => import("@/views/contact/Contact.vue"); //客服信箱
export const Recruit = () => import("@/views/recruit/Recruit.vue"); //人才招募
export const Location = () => import("@/views/location/Location.vue"); //公司位置
export const Media = () => import("@/views/media/Media.vue"); //社群媒體
export const EcoSystem = () => import("@/views/information/EcoSystem.vue"); //樂天生態圈
export const ExploreKobo = () => import("@/views/explore/ExploreKobo.vue"); //Kobo通路
export const ExploreShop = () => import("@/views/explore/ExploreShop.vue"); //Shop
export const ExploreRich = () => import("@/views/explore/ExploreRich.vue"); //Rich
export const ExploreRead = () => import("@/views/explore/ExploreRead.vue"); //Read
export const ExplorePlay = () => import("@/views/explore/ExplorePlay.vue"); //Play
export const NotFound = () => import("@/views/NotFound.vue"); //404頁面