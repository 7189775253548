import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache) {
  var _component_metainfo = _resolveComponent("metainfo");
  var _component_router_view = _resolveComponent("router-view");
  var _component_el_config_provider = _resolveComponent("el-config-provider");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_metainfo, null, {
    title: _withCtx(function (_ref) {
      var content = _ref.content;
      return [_createTextVNode(_toDisplayString(content), 1)];
    }),
    _: 1
  }), _createVNode(_component_el_config_provider, null, {
    "default": _withCtx(function () {
      return [_createVNode(_component_router_view)];
    }),
    _: 1
  })], 64);
}